(function () {
  'use strict';

  class AboutCtrl {
    constructor() {
      let vm = this;
      vm.ctrlName = 'AboutCtrl';
    }
  }

  /**
   * @ngdoc object
   * @name about.controller:AboutCtrl
   *
   * @description
   *
   */
  angular
    .module('about')
    .controller('AboutCtrl', AboutCtrl);
}());
